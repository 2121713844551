<template>
  <div v-if="value" class="d-alert" :class="{ 'position-upper': upper }">
    <div class="alert-icon vertical-center">
      <icon-base v-if="type == 'error'" color="#e03535" width="22" height="22">
        <d-icon-close2 />
      </icon-base>
      <icon-base v-if="type == 'success'" color="#0ef28f" width="24" height="24">
        <d-icon-done />
      </icon-base>
      <icon-base v-if="type == 'alert'" color="#ffbf18" width="24" height="24">
        <d-icon-alert />
      </icon-base>
      <icon-base v-if="type == 'info'" color="#4689f3" width="24" height="24">
        <d-icon-info />
      </icon-base>
    </div>
    <div class="alert-text" aria-live="assertive">
      <p class="alert-title">{{ alertTitle[type] }}</p>
      <p class="alert-message">{{ message }}</p>
    </div>
    <button class="close-btn" @click="$emit('input', false)" aria-label="Close popup" tabindex="0">
      <icon-base color="#8D909F" width="16" height="16">
        <d-icon-close2 />
      </icon-base>
    </button>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DIconDone from "../icons/DIconDone.vue";
import DIconAlert from "../icons/DIconAlert.vue";
import DIconInfo from "../icons/DIconInfo.vue";

export default {
  name: "DAlert",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    value: Boolean, //for v-model value
    showAlert: Boolean,
    type: {
      type: String,
      default: "info",
    },
    message: String,
    manualClose: Boolean, // if true, won't auto-close alert after 3 sec
    upper: Boolean,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconDone,
    DIconAlert,
    DIconInfo,
  },
  watch: {
    index: function () {
      if (this.value && !this.manualClose) {
        this.handleAutoClose();
      }
    },
    value: function (value) {
      if (value && !this.manualClose) {
        this.handleAutoClose();
      }
    },
  },
  created() {},
  updated() {},
  data: function () {
    return {
      alertTitle: {
        error: this.$t("alert.error"),
        info: this.$t("alert.info"),
        alert: this.$t("alert.alert"),
        success: this.$t("alert.success"),
      },
      showAlertTimeout: null,
    };
  },
  methods: {
    handleAutoClose() {
      clearTimeout(this.showAlertTimeout);
      this.showAlertTimeout = setTimeout(() => {
        this.$emit("input", false);
        // can't set input to false if v-model set to true by self.xxx
      }, 3000);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.d-alert {
  position: fixed;
  top: 86px;
  right: 20px;
  width: 454px;
  min-height: 80px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #2c2d32;
  text-align: left;
  z-index: 999;
}
@media (max-width: 600px) {
  .d-alert {
    min-width: 250px;
    max-width: 454px;
    width: calc(100% - 88px);
  }
}

.position-upper {
  top: 16px;
  right: 16px;
}
.alert-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  padding-left: 16px;
  padding-top: 4px;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.alert-text {
  position: relative;
  top: 0;
  padding: 16px 16px 0 56px;
}
.alert-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
}
.alert-message {
  color: #8d909f;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close-btn:focus {
  outline: none;
}
.close-btn:focus-visible {
  outline: var(--focus-visible-outline);
}
</style>
